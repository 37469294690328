import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import FormField from '../../../../components/BaseComponents';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {invokeActionRequest} from "../../../../ducks/gridActions";
import {errorMapping} from "../../../../utils/errorMapping";

const ParamsFromActions = ({ formModal }) => {
    const {ids, name, actionName, columns = [], open, onClose, backLoad, showConfirmation, closeConformation} = formModal;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [params, setParams] = useState({});
    const [errors, setErrors] = useState({});

    const handleClose = () => {
        setErrors({});
        setParams({});
        onClose();
    };

    const handleChange = (e, { name, value }) => {

        setParams(prev => {
            let form = {
                ...prev,
                [name]: value,
            };

            const autocompleteFields = columns.filter(
                i => i.autoComplete && Object.keys(i.autoComplete).includes(name),
            );

            if (autocompleteFields && autocompleteFields.length && value) {
                autocompleteFields.forEach(autocompleteField => {
                    form = {
                        ...form,
                        [autocompleteField.name]: value[autocompleteField.autoComplete[name]] || '',
                    };
                });
            }

            return form;
        });


        setErrors(prevErrors => prevErrors && prevErrors[name]
            ? Object.keys(prevErrors)
                .filter(i => i !== name)
                .map(i => prevErrors[i])
            : prevErrors)
    };

    const handleConfirm = () => {
        dispatch(
            invokeActionRequest({
                ids: ids,
                name: name,
                model: params,
                actionName,
                callbackError: errors => {
                    setErrors(errorMapping(errors))
                },
                callbackSuccess: () => {
                    handleClose();
                    backLoad && backLoad();
                },
                callbackConfirmation: (message) => {
                    handleClose();
                    showConfirmation(message, () => {
                        dispatch(invokeActionRequest({
                            ids,
                            name,
                            actionName,
                            isConfirmed: true,
                            model: params,
                            callbackSuccess: () => {
                                closeConformation();
                                backLoad && backLoad();
                            },
                        }))
                    }, () => {
                        dispatch(invokeActionRequest({
                            ids,
                            name,
                            actionName,
                            isConfirmed: false,
                            model: params,
                            callbackSuccess: () => {
                                closeConformation();
                                backLoad && backLoad();
                            },
                        }))
                    }, ['Yes', 'No'])
                }
            }),
        );
    };

    return (
        <Modal open={open}>
            <Modal.Header>{t('fillForm')}</Modal.Header>
            <Modal.Content>
                <div className="ui form dictionary-edit" style={{ padding: 0 }}>
                    {columns.map(column => {
                        return (
                            <FormField
                                {...column}
                                key={column.name}
                                error={errors && errors[column.name]}
                                value={params[column.name]}
                                onChange={handleChange}
                            />
                        );
                    })}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t('CancelButton')}</Button>
                <Button
                    primary
                    disabled={
                        !columns.filter(i => i.isRequired).every(i => params[i.name])
                    }
                    onClick={handleConfirm}
                >
                    {t('Ok')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ParamsFromActions;
