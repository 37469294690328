import React, {useEffect} from 'react';
import {Button, Divider, Form, Grid, Icon} from 'semantic-ui-react';
import FormField from '../formField';
import {useDispatch, useSelector} from 'react-redux';
import {SHIPPINGS_GRID} from '../../../../constants/grids';
import {useTranslation} from 'react-i18next';
import {
    clearError,
    createConfigSelector,
    createDefaultValueSelector,
    getCardConfigRequest,
    getCarrierRequest,
} from '../../../../ducks/gridCard';

const CreateShipping = ({form = {}, onChange, settings, error}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const columnsConfig = useSelector(createConfigSelector);

    const defaultValues = useSelector(createDefaultValueSelector);

    useEffect(() => {
        dispatch(getCardConfigRequest({name: SHIPPINGS_GRID}));
    }, []);

    useEffect(() => {
        defaultValues &&
        Object.keys(defaultValues).forEach(key =>
            onChange(null, {name: key, value: defaultValues[key]}),
        );
    }, [defaultValues]);

    const handleSelectCarrier = () => {
        dispatch(getCarrierRequest({
            name: SHIPPINGS_GRID,
            params: form,
            callbackSuccess: (tarifficationType, vehicleTypeId, carrierId) => {
                onChange(null, {
                    name: 'tarifficationType',
                    value: tarifficationType
                });
                onChange(null, {
                    name: 'vehicleTypeId',
                    value: vehicleTypeId
                });
                onChange(null, {
                    name: 'carrierId',
                    value: carrierId
                });
            }
        }))
    };

    const handleAddPoint = (fieldName) => {
        onChange(null, {
            name: fieldName,
            value: [
                ...(form[fieldName] && form[fieldName].length
                    ? form[fieldName]
                    : [
                        {},
                    ]),
                {},
            ],
        });
    };

    const handleDelete = (index, fieldName) => {
        const list = [...form[fieldName]];
        list.splice(index, 1);
        onChange(null, {
            name: fieldName,
            value: [...list],
        });
    };

    const handleChange = (e, {name, value, index, fieldName}) => {
        let obj = {
            [name]: value,
        };

        if (name === 'shippingWarehouseId') {
            obj = {
                ...obj,
                shippingCity: value ? value.city : null
            }
        }

        if (name === 'deliveryWarehouseId') {
            obj = {
                ...obj,
                deliveryCity: value ? value.city : null
            }
        }

        if (name === 'shippingCity') {
            obj = {
                ...obj,
                shippingWarehouseId: null
            }
        }

        if (name === 'deliveryCity') {
            obj = {
                ...obj,
                deliveryWarehouseId: null
            }
        }

        if (!form[fieldName] || !form[fieldName].length) {
            onChange(e, {
                name: fieldName,
                value: [
                    {
                        ...obj,
                    },
                ],
            });
        } else {
            const list = [...form[fieldName]];
            list[index] = {
                ...list[index],
                ...obj,
            };
            onChange(e, {
                name: fieldName,
                value: [...list],
            });
        }
    };

    console.log('form', form);

    return (
        <>
            <Form className="tabs-card">
                <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <FormField
                                name="tarifficationType"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                        {/* <Grid.Column>
                            <FormField
                                name="shippingDate"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="deliveryDate"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>*/}
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <div className="point_header">
                                <label>{t('Shipping points')}</label>
                                <Button icon onClick={() => handleAddPoint('loadingPoints')}>
                                    <Icon name='plus'/>
                                </Button>
                            </div>
                            {
                                (form.loadingPoints && form.loadingPoints.length ? form.loadingPoints : [{}]).map((item, index) => (
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={13}>
                                                <Grid style={{paddingTop: "24px"}}>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <FormField
                                                                name="shippingWarehouseId"
                                                                columnsConfig={columnsConfig}
                                                                settings={settings}
                                                                form={item}
                                                                error={{shippingWarehouseId: error["shippingWarehouseId_" + index]}}
                                                                onChange={(e, data) => {
                                                                    if (error["shippingWarehouseId_" + index]) {
                                                                        dispatch(clearError && clearError("shippingWarehouseId_" + index));
                                                                    }
                                                                    if (error["shippingCity_" + index]) {
                                                                        dispatch(clearError && clearError("shippingCity_" + index));
                                                                    }
                                                                    handleChange(e, {
                                                                        ...data,
                                                                        fieldName: 'loadingPoints',
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <FormField
                                                                name="shippingCity"
                                                                columnsConfig={columnsConfig}
                                                                settings={settings}
                                                                form={item}
                                                                error={{shippingCity: error["shippingCity_" + index]}}
                                                                onChange={(e, data) => {
                                                                    if (error["shippingCity_" + index]) {
                                                                        dispatch(clearError && clearError("shippingCity_" + index));
                                                                    }
                                                                    handleChange(e, {
                                                                        ...data,
                                                                        fieldName: 'loadingPoints',
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <FormField
                                                                name="shippingDate"
                                                                columnsConfig={columnsConfig}
                                                                settings={settings}
                                                                form={item}
                                                                error={{shippingDate: error["shippingDate_" + index]}}
                                                                onChange={(e, data) => {
                                                                    if (error["shippingDate_" + index]) {
                                                                        dispatch(clearError && clearError("shippingDate_" + index));
                                                                    }
                                                                    handleChange(e, {
                                                                        ...data,
                                                                        fieldName: 'loadingPoints',
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column width={3} verticalAlign="middle" textAlign="right" >
                                                <Button icon onClick={() => handleDelete(index, 'loadingPoints')}>
                                                    <Icon name='trash alternate'/>
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                ))
                            }
                        </Grid.Column>
                        <Grid.Column>
                            <div className="point_header">
                                <label>{t('Delivery points')}</label>
                                <Button icon onClick={() => handleAddPoint('unloadingPoints')}>
                                    <Icon name='plus'/>
                                </Button>
                            </div>
                            {
                                (form.unloadingPoints && form.unloadingPoints.length ? form.unloadingPoints : [{}]).map((item, index) => (
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={13}>
                                                <Grid style={{paddingTop: "24px"}}>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <FormField
                                                                name="deliveryWarehouseId"
                                                                columnsConfig={columnsConfig}
                                                                settings={settings}
                                                                form={item}
                                                                error={{deliveryWarehouseId: error["deliveryWarehouseId_" + index]}}
                                                                onChange={(e, data) => {
                                                                    if (error["deliveryWarehouseId_" + index]) {
                                                                        dispatch(clearError && clearError("deliveryWarehouseId_" + index));
                                                                    }
                                                                    if (error["deliveryCity_" + index]) {
                                                                        dispatch(clearError && clearError("deliveryCity_" + index));
                                                                    }
                                                                    handleChange(e, {
                                                                        ...data,
                                                                        fieldName: 'unloadingPoints',
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <FormField
                                                                name="deliveryCity"
                                                                columnsConfig={columnsConfig}
                                                                settings={settings}
                                                                form={item}
                                                                error={{deliveryCity: error["deliveryCity_" + index]}}
                                                                onChange={(e, data) => {
                                                                    if (error["deliveryCity_" + index]) {
                                                                        dispatch(clearError && clearError("deliveryCity_" + index));
                                                                    }
                                                                    handleChange(e, {
                                                                        ...data,
                                                                        fieldName: 'unloadingPoints',
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <FormField
                                                                name="deliveryDate"
                                                                columnsConfig={columnsConfig}
                                                                settings={settings}
                                                                form={item}
                                                                error={{deliveryDate: error["deliveryDate_" + index]}}
                                                                onChange={(e, data) => {
                                                                    if (error["deliveryDate_" + index]) {
                                                                        dispatch(clearError && clearError("deliveryDate_" + index));
                                                                    }
                                                                    handleChange(e, {
                                                                        ...data,
                                                                        fieldName: 'unloadingPoints',
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <FormField
                                                                name="deliveryTimeFrom"
                                                                columnsConfig={columnsConfig}
                                                                settings={settings}
                                                                form={item}
                                                                error={{deliveryTimeFrom: error["deliveryTimeFrom_" + index]}}
                                                                onChange={(e, data) => {
                                                                    if (error["deliveryTimeFrom_" + index]) {
                                                                        dispatch(clearError && clearError("deliveryTimeFrom_" + index));
                                                                    }
                                                                    handleChange(e, {
                                                                        ...data,
                                                                        fieldName: 'unloadingPoints',
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <FormField
                                                                name="deliveryTimeTo"
                                                                columnsConfig={columnsConfig}
                                                                settings={settings}
                                                                form={item}
                                                                error={{deliveryTimeTo: error["deliveryTimeTo_" + index]}}
                                                                onChange={(e, data) => {
                                                                    if (error["deliveryTimeTo_" + index]) {
                                                                        dispatch(clearError && clearError("deliveryTimeTo_" + index));
                                                                    }
                                                                    handleChange(e, {
                                                                        ...data,
                                                                        fieldName: 'unloadingPoints',
                                                                        index
                                                                    })
                                                                }}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column width={3} verticalAlign="middle" textAlign="right">
                                                <Button icon onClick={() => handleDelete(index, 'unloadingPoints')}>
                                                    <Icon name='trash alternate'/>
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                ))
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Divider/>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <FormField
                                name="monoPalletsCount"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="mixPalletsCount"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="palletsCount"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <FormField
                                name="totalWeightKg"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="vehicleTypeId"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="totalOrderAmountExcludingVAT"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <FormField
                                name="carrierId"
                                columnsConfig={columnsConfig}
                                settings={settings}
                                form={form}
                                error={error}
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Button basic color='blue' style={{marginTop: '24px'}} onClick={handleSelectCarrier}>
                                {t('Choose a carrier')}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </>
    );
};

export default CreateShipping;
