import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupRequest, valuesListSelector } from '../../../ducks/lookup';
import CheckBox from '../../../components/BaseComponents/Checkbox';
import {useTranslation} from "react-i18next";
import Time from "../../../components/BaseComponents/Time";

const ScheduleTab = ({ form, error, onChange }) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const weekDay = useSelector(state => valuesListSelector(state, 'weekDay'));

    useEffect(() => {
        dispatch(
            getLookupRequest({
                name: 'weekDay',
                isForm: true,
            }),
        );
    }, []);

    const handleChange = (e, { name, value, isCheck, weekDay: currentWeekDay }) => {

        let currentValue = [...(form.scheduleEntries || [])];

        if (isCheck) {
            const index = currentValue.findIndex(i => i.weekDay && i.weekDay.value === name);

            if (index === -1 && value) {
                currentValue = [
                    ...currentValue,
                    {
                        weekDay: {
                            ...weekDay.find(i => i.value === name),
                        },
                    },
                ];
            } else if (index !== -1) {
                currentValue.splice(index, 1);
            }
        } else {
            const index = currentValue.findIndex(i => i.weekDay && i.weekDay.value === currentWeekDay);
            currentValue[index] = {
                ...currentValue[index],
                [name]: value
            }
        }

        onChange(e, { name: 'scheduleEntries', value: currentValue });
    };

    return (
        <div className="tabs-card tabs-card__box-list">
            {weekDay.map(i => {
                const checkValue = form.scheduleEntries &&
                    form.scheduleEntries.find(day => i.value === (day.weekDay && day.weekDay.value)) || {};

                return (
                    <div key={i.value} className="shedule-tab">
                        <CheckBox
                            name={i.value}
                            label={t(i.name)}
                            value={i}
                            checked={Boolean(checkValue.weekDay)}
                            onChange={(e, { name, value }) =>
                                handleChange(e, { name, value, isCheck: true })
                            }
                        />
                        <Time
                            noLabel
                            isDisabled={!checkValue.weekDay}
                            value={checkValue.fromTime}
                            weekDay={i.name}
                            name="fromTime"
                            onChange={handleChange}
                        />
                        -
                        <Time
                            noLabel
                            isDisabled={!checkValue.weekDay}
                            value={checkValue.toTime}
                            weekDay={i.name}
                            name="toTime"
                            onChange={handleChange}
                        />
                    </div>
                )
            })}
        </div>
    );
};

export default ScheduleTab;
