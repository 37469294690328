import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CardLayout from '../../components/CardLayout';
import {Button, Confirm, Dropdown, Icon, Modal, Popup} from 'semantic-ui-react';
import FormField from '../../components/BaseComponents';
import {
    additionalBlocksSelector,
    canDeleteSelector,
    cardProgressSelector,
    cardSelector,
    clearDictionaryCard,
    columnsCardSelector,
    deleteDictionaryEntryRequest,
    errorSelector,
    getCardConfigRequest,
    getCardRequest,
    getDictionaryCardDefaultValueRequest,
    progressSelector,
    saveDictionaryCardRequest,
} from '../../ducks/dictionaryView';
import {actionsCardSelector, getActionsRequest, invokeActionRequest} from "../../ducks/gridActions";
import TabsIndex from "./components/tabsIndex";
import Content from "./components/content";
import {getBacklightsDictionaryRequest} from "../../ducks/profile";


const CardNew = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        match = {},
        history,
        location,
        load,
        isModal,
        openModal,
        onClose: onCloseModal,
    } = props;
    const { params = {} } = match;
    const { name, id } = params;
    const { state = {} } = location;
    const { defaultForm = {}, columns: propsColumns } = state;

    let [form, setForm] = useState({ ...defaultForm });
    let [confirmation, setConfirmation] = useState({ open: false });
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [dependentFields, setDependentFields] = useState({});

    const columns = useSelector(state =>
        propsColumns ? propsColumns : columnsCardSelector(state, name, id),
    );
    const canDelete = useSelector(state => canDeleteSelector(state, name));
    const loading = useSelector(state => cardProgressSelector(state));
    const progress = useSelector(state => progressSelector(state));
    const card = useSelector(state => cardSelector(state));
    const error = useSelector(state => errorSelector(state));
    const actions = useSelector(state => actionsCardSelector(state));
    const additionalBlocks = useSelector(state => additionalBlocksSelector(state, name));

    useEffect(() => {
        id && dispatch(getCardConfigRequest({ id, name }));
        loadCard();

        return () => {
            dispatch(clearDictionaryCard());
        };
    }, []);

    useEffect(() => {
        setForm(form => ({
            ...card,
            ...form,
        }));
    }, [card]);

    useEffect(() => {
        let obj = {};
        columns && columns.length && columns
            .filter(column => column.dependencies && column.dependencies.length)
            .forEach(column => {
                column.dependencies.forEach(item => {
                    obj = {
                        ...obj,
                        [item]: [...(obj[item] || []), column.name],
                    };
                });
            });

        setDependentFields(obj);
    }, [columns]);

    const loadCard = () => {
        id
            ? dispatch(getCardRequest({ id, name }))
            : dispatch(getDictionaryCardDefaultValueRequest(name));

        id &&
        dispatch(
            getActionsRequest({
                name,
                ids: [id],
                isCard: true,
            }),
        );

    };


    const onOpenModal = () => {};

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const title = useMemo(
        () => (id ? `${t(name)}: ${t('edit_record')}` : `${t(name)}: ${t('new_record')}`),
        [name, id],
    );

    const getActionsFooter = useCallback(() => {
        return (
            <>
                <Button color="grey" onClick={handleClose}>
                    {t('CancelButton')}
                </Button>
                <Button
                    color="blue"
                    disabled={notChangeForm || progress}
                    loading={progress}
                    onClick={handleSave}
                >
                    {t('SaveButton')}
                </Button>
            </>
        );
    }, [form, notChangeForm, progress]);

    const handleSave = () => {
        let params = {
            ...form,
        };

        if (id) {
            params = {
                ...params,
                id,
            };
        }

        const callbackConfirmation = message => {
            setConfirmation({
                open: true,
                content: message,
                onCancel: confirmClose,
                onConfirm: () => {
                    confirmClose();
                    dispatch(
                        saveDictionaryCardRequest({
                            params,
                            name,
                            callbackSuccess: () => {
                                load && load(form);
                                onClose();
                            },
                            isConfirmed: true,
                        }),
                    );
                },
            });
        };

        dispatch(
            saveDictionaryCardRequest({
                params,
                name,
                callbackSuccess: () => {
                    load && load(form);
                    dispatch(getBacklightsDictionaryRequest());
                    onClose();
                },
                isConfirmed: false,
                callbackConfirmation,
            }),
        );
    };

    const handleDelete = () => {
        showConfirmation(
            t('confirm_delete_dictionary'),
            () => {
                confirmClose();
                dispatch(
                    deleteDictionaryEntryRequest({
                        name,
                        id,
                        callbackSuccess: onClose,
                    }),
                );
            },
            confirmClose,
        );
    };


    const invokeAction = (actionName) => {
            showConfirmation(
                `${t('Are you sure to complete')} "${t(actionName)}"?`,
                () => {
                    confirmClose();
                    dispatch(
                        invokeActionRequest({
                            ids: [id],
                            name,
                            actionName,
                            callbackSuccess: () => {
                                dispatch(getBacklightsDictionaryRequest());
                                if (actionName.toLowerCase().includes('delete')) {
                                    onClose();
                                } else {
                                    loadCard();
                                }
                            },
                        }),
                    );
                },
                confirmClose,
            );
    };

    console.log('actions', actions);

    const getActionsHeader = useCallback(() => {
        return (
            <div>
                {canDelete ? (
                    <Popup
                        content={t('delete')}
                        position="bottom right"
                        trigger={
                            <Button icon onClick={handleDelete}>
                                <Icon name="trash alternate outline" />
                            </Button>
                        }
                    />
                ) : null}
                {
                    actions && actions.length ?
                        <Dropdown
                            icon="ellipsis horizontal"
                            floating
                            button
                            pointing="top right"
                            className="icon"
                            scrolling
                        >
                            <Dropdown.Menu>
                                {actions
                                    .filter(item => item.allowedFromForm)
                                    .map(action => (
                                        <Dropdown.Item
                                            key={action.name}
                                            text={t(action.name)}
                                            label={{
                                                color: action.color,
                                                empty: true,
                                                circular: true,
                                            }}
                                            onClick={() => invokeAction(action.name, action.model)}
                                        />
                                    ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        : null
                }

            </div>
        );
    }, [actions]);

    const handleChange = useCallback(
        (event, { name, value, ...prev }) => {
            if (notChangeForm) {
                setNotChangeForm(false);
            }
            let formNew = {
                ...form,
                [name]: value,
            };

            if (dependentFields[name] && dependentFields[name].length) {
                dependentFields[name].forEach(item => {
                    formNew = {
                        ...formNew,
                        [item]: "",
                    }
                })
            }

            const autocompleteFields = columns.filter(
                i => i.autoComplete && Object.keys(i.autoComplete).includes(name),
            );

            if (autocompleteFields && autocompleteFields.length && value) {
                autocompleteFields.forEach(autocompleteField => {
                    formNew = {
                        ...formNew,
                        [autocompleteField.name]: value[autocompleteField.autoComplete[name]] || '',
                    };
                });
            }

            setForm(formNew);
        },
        [notChangeForm, form, dependentFields],
    );

    const confirmClose = () => {
        setConfirmation({ open: false });
    };

    const onClose = () => {
        isModal
            ? onCloseModal()
            : history.replace({
                  pathname: location.state.pathname,
                  state: {
                      ...location.state,
                      pathname: location.state.gridLocation
                          ? location.state.gridLocation
                          : location.state.pathname,
                  },
              });
    };

    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: onClose,
            });
        }
    };

    return (
        <>
            {isModal ? (
                <Modal
                    dimmer="blurring"
                    open={openModal}
                    closeOnDimmerClick={false}
                    onOpen={onOpenModal}
                    onClose={onCloseModal}
                    closeIcon
                >
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Description>
                        <Content
                            columns={columns}
                            error={error}
                            form={form}
                            handleChange={handleChange}
                        />
                    </Modal.Description>
                    <Modal.Actions>{getActionsFooter()}</Modal.Actions>
                </Modal>
            ) : (
                <TabsIndex
                    columns={columns}
                    form={form}
                    error={error}
                    title={title}
                    loading={loading}
                    additionalBlocks={additionalBlocks}
                    getActionsFooter={getActionsFooter}
                    getActionsHeader={getActionsHeader}
                    onClose={handleClose}
                    onChange={handleChange}
                />
            )}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </>
    );
};

export default CardNew;
