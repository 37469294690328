import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, Form, Visibility } from 'semantic-ui-react';

import './style.scss';
import {
    clearFormLookup,
    getLookupRequest,
    listFromSelectSelector,
    listSelector,
    progressSelector,
    totalCounterSelector,
    valuesListSelector,
} from '../../ducks/lookup';
import { PAGE_SIZE } from '../../constants/settings';
import { debounce } from 'throttle-debounce';
import { values } from 'lodash';

const Select = ({
    value = {},
    onChange,
    placeholder = '',
    isDisabled,
    label,
    name,
    form,
    text,
    multiple,
    loading,
    clearable,
    source,
    isTranslate,
    error,
    textValue,
    noLabel,
    isRequired,
    autoComplete,
    children,
    upward,
    isBulkUpdateOnly,
    sourceParams,
    isGrid,
    formRef,
    extendable,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const context = useRef(null);
    const selectRef = useRef(null);
    let timer = useRef(null);

    let [open, setOpen] = useState(false);
    // let [items, setItems] = useState([]);
    let [counter, setCounter] = useState(PAGE_SIZE);
    let [searchQuery, setSearchQuery] = useState('');
    let [filter, setFilter] = useState('');
    let [dependentFields, setDependentFields] = useState([]);

    const valuesList = useSelector(state =>
        listFromSelectSelector(state, source, t, filter, isTranslate, counter, isBulkUpdateOnly),
    );
    const totalCounter = useSelector(state =>
        totalCounterSelector(state, source, t, filter, isTranslate),
    );
    const progress = false;

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setFilter(searchQuery);
        }, 300);
    }, [searchQuery]);

    useEffect(() => {
        context.current.scrollTop = 0;
        setCounter(PAGE_SIZE);
    }, [filter]);

    useEffect(() => {
        if (valuesList && valuesList.length !== 0 && extendable) {
            const dependentFieldsKeys = Object.keys(valuesList[0]).filter(i => i !== "name" 
            && i !== "value" && i !== "isFilterOnly" && i !== "isBulkUpdateOnly");
            setDependentFields(dependentFieldsKeys);
        }

    }, [valuesList]);

    useEffect(() => {
        //handleOpen();
        setTimeout(() => {
            if (isGrid && selectRef && selectRef.current) {
               selectRef.current.handleFocus();
            }
        }, 100)
    }, []);

    const handleChange = (e, { value, onAdd }) => {
        setSearchQuery('');
        toggle(false); 
        if (extendable) {
            const newValue = value;
            if (value && onAdd) {
                dependentFields.forEach(field => {
                    newValue[field] = form[field];
                })
            } 
            onChange(e, { value: newValue ? newValue : null, name, extendable: true });
        } else {
            onChange(e, { value: value ? value : null, name });
        }
        handleClose();

        if (isGrid) {
            setTimeout(() => {
                formRef &&
                formRef.current &&
                formRef.current.handleSubmit &&
                formRef.current.handleSubmit();
            }, 100);
        }
    };
    

    const handleOpen = () => {
        dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
                sourceParams,
            }),
        );
        toggle(true);
    };

    const handleClose = () => {
        context.current.scrollTop = 0;
        setCounter(PAGE_SIZE);
        // dispatch(clearFormLookup(source));
    };

    const handleBlur = () => {
        toggle(false);
        setSearchQuery('');
    };

    const toggle = value => {
        setOpen(value);
    };

    const scroll = () => {
        if (counter < totalCounter) {
            setCounter(prevState => prevState + PAGE_SIZE);
        }
    };

    const handleSearchChange = (e, { searchQuery }) => {
        setSearchQuery(searchQuery);
    };

    const handleFocus = () => {
        toggle(true);
        handleOpen();
    };


    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                </label>
            ) : null}
            <div className={`form-select ${isDisabled ? 'form-select_disabled' : ''}`}>
                <Dropdown
                    ref={selectRef}
                    placeholder={t(placeholder)}
                    fluid
                    selection={!isGrid}
                    loading={progress}
                    search={!isGrid}
                    clearable={value ? extendable ? value : value.value : null}
                    value={value ? extendable ? value : value.value : null}
                    searchQuery={searchQuery}
                    text={value ? extendable ? value : value.name : null}
                    error={error}
                    disabled={isDisabled}
                    closeOnChange={true}
                    closeOnBlur={true}
                    onBlur={handleBlur}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    onSearchChange={handleSearchChange}
                    onFocus={handleFocus}
                    open={open}
                    upward={upward}
                    onChange={handleChange}
                >
                    <div role="listbox" className={`menu transition${isGrid ? ' select_grid' : ''}`} ref={context}>
                        {valuesList && valuesList.length ? (
                            valuesList.map(item => (
                                extendable ?
                                <Dropdown.Item
                                    style={item.isBulkUpdateOnly ? { color: '#9a0000' } : {}}
                                    key={item.value}
                                    selected={value && item.value === value}
                                    active={value && item.value === value}
                                    value={item.value}
                                    onClick={e => handleChange(e, { value: item })}
                                >
                                    {item.name}
                                </Dropdown.Item> :
                                <Dropdown.Item
                                style={item.isBulkUpdateOnly ? { color: '#9a0000' } : {}}
                                key={item.value}
                                selected={value && item.value === value.value}
                                active={value && item.value === value.value}
                                value={item.value}
                                onClick={e => handleChange(e, { value: item })}
                                >
                                    {item.name}
                                </Dropdown.Item>
                            ))
                        ) : (
                            extendable && searchQuery !== "" ?  
                            <Dropdown.Item
                                key={searchQuery}
                                value={searchQuery}
                                onClick={e => handleChange(e, {value :{value: searchQuery, name: searchQuery}, onAdd: true})}
                                className="add-extendable-item"
                            >
                                {t("AddButton") + " " + searchQuery}
                            </Dropdown.Item> :
                            <div className="message">No results found.</div>
                        )}
                        <Visibility
                            continuous={false}
                            once={false}
                            context={context.current}
                            onTopVisible={scroll}
                            /*style={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                zIndex: -1,
                            }}*/
                        />
                    </div>
                </Dropdown>
                {children && children}
            </div>
            {error && typeof error === 'string' && <span className="label-error">{error}</span>}
        </Form.Field>
    );
};

export default React.memo(Select);
