import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Confirm, Dropdown } from 'semantic-ui-react';
import {
    addError,
    cardColumnsConfigSelector,
    cardSelector,
    clearGridCard,
    createAndSendRequest,
    createGridCardRequest,
    editCardRequest,
    editProgressSelector,
    errorSelector,
    getCardRequest,
    getGridCardConfigRequest,
    isUniqueNumberRequest,
    progressSelector,
    selectCarrierRequest,
    settingsFormSelector,
} from '../../ducks/gridCard';
import {
    actionsCardSelector,
    clearActions,
    getActionsRequest,
    invokeActionRequest,
    progressActionNameSelector,
} from '../../ducks/gridActions';
import { ORDERS_GRID, SHIPPINGS_GRID } from '../../constants/grids';
import { DICTIONARY_CARD_LINK, DICTIONARY_NEW_LINK, GRID_CARD_LINK } from '../../router/links';
import { clearHistory, getHistoryRequest } from '../../ducks/history';
import { getFieldsSettingRequest } from '../../ducks/fieldsSetting';
import { roleIdSelector } from '../../ducks/profile';
import OrderCard from './components/orderCard';
import ShippingCard from './components/shippingCard';
import OtherCard from './components/otherCard';
import { columnsGridSelector } from '../../ducks/gridList';
import { TEXT_TYPE } from '../../constants/columnTypes';
import ParamsFromActions from './components/paramsFromActions';

const Card = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { match, history, location } = props;
    const { params = {} } = match;
    const { name, id } = params;

    let [form, setForm] = useState({});
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [confirmation, setConfirmation] = useState({ open: false });
    let [dependentFields, setDependentFields] = useState({});
    let [formModal, setFormModal] = useState({ open: false, columns: [] });

    const title = useMemo(
        () =>
            id
                ? t(`edit_${name}`, {
                      number: name === ORDERS_GRID ? form.orderNumber : form.shippingNumber,
                      status: t(form.status),
                  })
                : t(`new_${name}`),
        [name, id, form],
    );

    const card = useSelector(state => cardSelector(state));
    const settings = useSelector(state => settingsFormSelector(state, card.status));
    const error = useSelector(state => errorSelector(state));
    const roleId = useSelector(state => roleIdSelector(state));
    const columnsConfig = useSelector(state => cardColumnsConfigSelector(state, name));

    useEffect(() => {
        dispatch(clearActions());

        id && loadCard();

        dispatch(
            getFieldsSettingRequest({
                forEntity: name,
                roleId,
            }),
        );

        return () => {
            dispatch(clearHistory());
            dispatch(clearGridCard());
        };
    }, []);

    useEffect(() => {
        if (notChangeForm) {
            Object.keys(form).forEach(key => {
                if (form[key] !== card[key]) {
                    setNotChangeForm(false);
                }
            });
        }

        Object.keys(columnsConfig).forEach(key => {
            if (
                columnsConfig[key].isSaveRequired &&
                form.id &&
                form[key] !== null &&
                form[key] !== undefined &&
                form[key] !== card[key]
            ) {
                handleSave();
            }
        });
    }, [form]);

    useEffect(() => {
        let obj = {};
        columnsConfig &&
            columnsConfig.length &&
            Object.keys(columnsConfig)
                .filter(
                    key =>
                        columnsConfig[key].dependencies && columnsConfig[key].dependencies.length,
                )
                .forEach(key => {
                    columnsConfig[key].dependencies.forEach(item => {
                        obj = {
                            ...obj,
                            [item]: [...(obj[item] || []), columnsConfig[key].name],
                        };
                    });
                });

        setDependentFields(obj);
    }, [columnsConfig]);

    const loadCard = () => {
        id &&
            dispatch(
                getCardRequest({
                    name,
                    id,
                    callbackSuccess: card => {
                        setForm(card);
                        setNotChangeForm(true);
                        if (card.validationResult) {
                            card.validationResult &&
                                card.validationResult._errors &&
                                card.validationResult._errors.forEach(item => {
                                    dispatch(addError(item));
                                });
                        }
                    },
                }),
            );
        id &&
            dispatch(
                getActionsRequest({
                    name,
                    ids: [id],
                    isCard: true,
                }),
            );
        id &&
            dispatch(
                getGridCardConfigRequest({
                    gridName: name,
                    id,
                }),
            );
        id && dispatch(getHistoryRequest(id));
    };

    const onClose = () => {
        const { state } = location;
        const { pathname, gridLocation } = state;

        history.replace({
            pathname: pathname,
            state: {
                ...state,
                pathname: gridLocation,
            },
        });
    };

    const handleClose = isConfirm => {
        if (!isConfirm || notChangeForm) {
            onClose();
        } else {
            showConfirmation(
                t('confirm_close_dictionary'),
                () => {
                    closeConfirmation();
                    onClose();
                },
                () => {
                    closeConfirmation();
                },
            );
        }
    };

    const onChangeForm = useCallback(
        (e, { name, value, extendable }) => {
            setForm(prevState => {
                let formNew = {
                    ...prevState,
                    [name]: value && extendable ? value.value : value,
                };

                if (dependentFields[name] && dependentFields[name].length) {
                    dependentFields[name].forEach(item => {
                        formNew = {
                            ...formNew,
                            [item]: '',
                        };
                    });
                }

                const autocompleteFields = Object.keys(columnsConfig).filter(
                    key =>
                        columnsConfig[key].autoComplete &&
                        Object.keys(columnsConfig[key].autoComplete).includes(name),
                );

                if (
                    autocompleteFields &&
                    autocompleteFields.length &&
                    value &&
                    typeof value === 'object'
                ) {
                    autocompleteFields.forEach(key => {
                        formNew = {
                            ...formNew,
                            [columnsConfig[key].name]:
                                value[columnsConfig[key].autoComplete[name]] || '',
                        };
                    });
                }

                return formNew;
            });
        },
        [columnsConfig],
    );

    const saveOrEditForm = callbackFun => {
        let newForm = { ...form };

        Object.keys(form).forEach(key => {
            if (
                columnsConfig[key] &&
                columnsConfig[key].type === TEXT_TYPE &&
                form[key] &&
                typeof form[key] === 'object'
            ) {
                newForm = {
                    ...newForm,
                    [key]: form[key].name,
                };
            }
        });

        dispatch(
            editCardRequest({
                name,
                params: newForm,
                callbackConfirmation: message => {
                    closeConfirmation();
                    showConfirmation(
                        message,
                        () => {
                            dispatch(
                                editCardRequest({
                                    name,
                                    params: {
                                        ...newForm,
                                        isConfirmed: true,
                                    },
                                    callbackSuccess: () => {
                                        closeConfirmation();
                                        loadCard();
                                    },
                                }),
                            );
                        },
                        () => {
                            dispatch(
                                editCardRequest({
                                    name,
                                    params: {
                                        ...newForm,
                                        isConfirmed: false,
                                    },
                                    callbackSuccess: () => {
                                        closeConfirmation();
                                        loadCard();
                                    },
                                }),
                            );
                            closeConfirmation();
                        },
                        ['Yes', 'No'],
                    );
                },
                callbackSuccess: callbackFun
                    ? callbackFun
                    : () => {
                          if (form.id) {
                              setNotChangeForm(true);
                              loadCard();
                          } else {
                              handleClose();
                          }
                      },
            }),
        );
    };

    const createForm = () => {
        dispatch(
            createGridCardRequest({
                name,
                params: form,
                callbackSuccess: handleClose,
            }),
        );
    };

    const handleSave = () => {
        setNotChangeForm(true);
        if (name === ORDERS_GRID && !form.id) {
            handleUniquenessCheck(saveOrEditForm);
        } else if (name === SHIPPINGS_GRID && !form.id) {
            createForm();
        } else {
            saveOrEditForm();
        }
    };

    const closeConfirmation = () => {
        setConfirmation({
            open: false,
        });
    };

    const showConfirmation = (content, onConfirm, onCancel, buttons) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
            buttons,
        });
    };

    const invokeAction = (actionName, model) => {
        if (model) {
            setFormModal({
                open: true,
                columns: model.columns,
                actionName,
                name,
                ids: [id],
                onClose: () => {
                    setFormModal({ open: false });
                },
                backLoad: loadCard,
            });
        } else {
            showConfirmation(
                `${t('Are you sure to complete')} "${t(actionName)}"?`,
                () => {
                    closeConfirmation();
                    dispatch(
                        invokeActionRequest({
                            ids: [id],
                            name,
                            actionName,
                            callbackSuccess: () => {
                                if (actionName.toLowerCase().includes('delete')) {
                                    onClose();
                                } else {
                                    loadCard();
                                }
                            },
                            callbackConfirmation: message => {
                                closeConfirmation();
                                showConfirmation(
                                    message,
                                    () => {
                                        dispatch(
                                            invokeActionRequest({
                                                ids: [id],
                                                name,
                                                isConfirmed: true,
                                                actionName,
                                                callbackSuccess: () => {
                                                    closeConfirmation();
                                                    loadCard();
                                                },
                                            }),
                                        );
                                    },
                                    () => {
                                        dispatch(
                                            invokeActionRequest({
                                                ids: [id],
                                                name,
                                                isConfirmed: false,
                                                actionName,
                                                callbackSuccess: () => {
                                                    closeConfirmation();
                                                    loadCard();
                                                },
                                            }),
                                        );
                                        closeConfirmation();
                                    },
                                    ['Yes', 'No'],
                                );
                            },
                        }),
                    );
                },
                closeConfirmation,
            );
        }
    };

    const handleUniquenessCheck = callbackFunc => {
        dispatch(
            isUniqueNumberRequest({
                number: form.orderNumber,
                fieldName: 'orderNumber',
                errorText: t('number_already_exists'),
                callbackSuccess: callbackFunc,
            }),
        );
    };

    const handleCreateAndSend = () => {
        dispatch(
            createAndSendRequest({
                name,
                params: form,
                callbackSuccess: handleClose,
            }),
        );
    };

    const loading = useSelector(state => progressSelector(state));
    const editLoading = useSelector(state => editProgressSelector(state));
    const actions = useSelector(state => actionsCardSelector(state));
    const progressActionName = useSelector(state => progressActionNameSelector(state));
    const disableSave = useMemo(() => {
        return Boolean(progressActionName || notChangeForm);
    }, [progressActionName, notChangeForm]);

    const goToSoldTo = (id, params) => {
        const go = () => {
            const { state } = location;
            history.replace({
                pathname: id
                    ? DICTIONARY_CARD_LINK.replace(':name', 'warehouses').replace(':id', id)
                    : DICTIONARY_NEW_LINK.replace(':name', 'warehouses'),
                state: {
                    ...state,
                    pathname: history.location.pathname,
                    gridLocation: state.gridLocation ? state.gridLocation : state.pathname,
                    ...params,
                },
            });
        };

        if (notChangeForm) {
            go();
        } else {
            showConfirmation(
                t('confirm_close'),
                () => {
                    closeConfirmation();
                    saveOrEditForm(go);
                },
                () => {
                    closeConfirmation();
                },
            );
        }
    };

    const getActionsFooter = useCallback(() => {
        if (name === SHIPPINGS_GRID && !id) {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={disableSave || editLoading}
                        loading={editLoading}
                        onClick={handleSave}
                    >
                        {t('createShipping')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={disableSave || editLoading}
                        loading={editLoading}
                        onClick={handleCreateAndSend}
                    >
                        {t('createShippingAndSendToTk')}
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        disabled={disableSave || editLoading}
                        loading={editLoading}
                        onClick={handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                </>
            );
        }
    }, [form, disableSave, editLoading, name]);

    const goToCard = (gridName, cardId) => {
        const { state } = location;
        history.replace({
            pathname: GRID_CARD_LINK.replace(':name', gridName).replace(':id', cardId),
            state: {
                ...state,
                pathname: history.location.pathname,
                gridLocation: state.gridLocation ? state.gridLocation : state.pathname,
            },
        });
    };

    const invokeCarrierSelection = () => {
        dispatch(
            selectCarrierRequest({
                ids: [form.id],
                callbackSuccess: () => {
                    setNotChangeForm(true);
                    loadCard();
                },
            }),
        );
    };

    const getActionsHeader = useCallback(() => {
        return (
            <div className="grid-card-header">
                {name === ORDERS_GRID && form.shippingId ? (
                    <div
                        className="link-cell"
                        onClick={() => goToCard(SHIPPINGS_GRID, form.shippingId)}
                    >
                        {t('open_shipping', { number: form.shippingNumber })}
                    </div>
                ) : null}
                {name === SHIPPINGS_GRID && form.orders && form.orders.length ? (
                    <Dropdown
                        text={t('orders')}
                        pointing="top right"
                        className="dropdown-blue"
                        scrolling
                    >
                        <Dropdown.Menu>
                            {form.orders.map(order => (
                                <Dropdown.Item
                                    className="link-cell"
                                    key={order.id}
                                    text={order.orderNumber}
                                    onClick={() => {
                                        goToCard(ORDERS_GRID, order.id);
                                    }}
                                />
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                ) : null}
                {name === SHIPPINGS_GRID && form.canSelectCarrier ? (
                    <Button onClick={invokeCarrierSelection}>{t('selectCarrier')}</Button>
                ) : null}
                <Dropdown
                    icon="ellipsis horizontal"
                    floating
                    button
                    pointing="top right"
                    className="icon"
                    scrolling
                >
                    <Dropdown.Menu>
                        {actions &&
                            actions
                                .filter(item => item.allowedFromForm)
                                .map(action => (
                                    <Dropdown.Item
                                        key={action.name}
                                        text={t(action.name)}
                                        label={{
                                            color: action.color,
                                            empty: true,
                                            circular: true,
                                        }}
                                        onClick={() => invokeAction(action.name, action.model)}
                                    />
                                ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }, [form, actions, name]);

    const cardContent = () => {
        const params = {
            ...props,
            id,
            load: loadCard,
            name,
            form,
            title,
            settings,
            loading,
            uniquenessNumberCheck: handleUniquenessCheck,
            error,
            goToSoldTo,
            onClose: handleClose,
            onChangeForm: onChangeForm,
            actionsFooter: getActionsFooter,
            actionsHeader: getActionsHeader,
            columnsConfig: columnsConfig,
        };

        const getContent = () => {
            switch (name) {
                case ORDERS_GRID:
                    return <OrderCard />;
                case SHIPPINGS_GRID:
                    return <ShippingCard />;
                default:
                    return <OtherCard />;
            }
        };

        return React.cloneElement(getContent(), params);
    };

    return (
        <React.Fragment>
            {cardContent()}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel || closeConfirmation}
                cancelButton={
                    confirmation.buttons && confirmation.buttons[1]
                        ? t(confirmation.buttons[1])
                        : t('cancelConfirm')
                }
                confirmButton={
                    confirmation.buttons && confirmation.buttons[0]
                        ? t(confirmation.buttons[0])
                        : t('Yes')
                }
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
            <ParamsFromActions formModal={formModal} />
        </React.Fragment>
    );
};

export default Card;
