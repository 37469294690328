import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Number = ({
    value = '',
    name,
    onChange,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    placeholder,
    isRequired,
    isInteger,
    autoFocus,
    isGrid,
}) => {
    const { t } = useTranslation();

    const handleOnChange = (e, { value }) => {
        if (isInteger && value.length <= 9) {
            onChange(e, {
                name,
                value: value.toString().replace(/[^\d-]/g, ''),
            });
        }

        if (!isInteger && value.length <= 12) {
            onChange(e, {
                name,
                value: value.toString(),
            }); 
        }
       
    };

    const newValue = parseFloat(value);

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: `${t(text || name)}${isRequired ? ' *' : ''}` }} />
                </label>
            ) : null}
            <Input
                placeholder={t(placeholder)}
                className={className}
                type={isInteger ? 'text' : 'number'}
                error={error}
                disabled={isDisabled || false}
                name={name}
                value={newValue == value ? newValue : value}
                step="any"
                transparent={isGrid}
                autoFocus={autoFocus}
                onChange={handleOnChange}
                autoComplete="off"
            />
            {error && typeof error === 'string' ? (
                <span className="label-error">{error}</span>
            ) : null}
        </Form.Field>
    );
};
export default Number;
