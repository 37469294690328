import React, { useCallback } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Form, Grid } from 'semantic-ui-react';
import FormField from '../../../../components/BaseComponents';
import Field from '../formField';
import {
    BIG_TEXT_TYPE,
    DATE_TYPE,
    DATE_TIME_TYPE,
    NUMBER_TYPE,
    TEXT_TYPE,
    STATE_TYPE,
    TIME_TYPE,
    INTEGER_TYPE,
} from '../../../../constants/columnTypes';
import {clearError, settingsExtSelector} from '../../../../ducks/gridCard';

const Route = ({
    name,
    form = {},
    point = {},
    onChange,
    pointChange,
    index,
    columnsConfig,
    settings: baseSettings,
    error,
}) => {
    const settings = useSelector(state => settingsExtSelector(state, form.status));
    const dispatch = useDispatch();

    const handleChange = useCallback(
        (e, { name, value }) => {
            if (error[`${name}_${index}`]) {
                dispatch(clearError && clearError(`${name}_${index}`));
                const e = Object.keys(error).filter(i => i.includes('plannedDate'));

                if (e && e.length) {
                  e.forEach(i => {
                      dispatch(clearError && clearError(i));
                  })
                }
            }
            pointChange(
                {
                    ...point,
                    [name]: value,
                },
                index,
            );
        },
        [point, error],
    );



    return (
        <Form style={{ paddingLeft: '12px' }}>
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <FormField
                            name="plannedDate"
                            text={point.isLoading ? 'plannedDate_loading' : 'plannedDate_delivery'}
                            error={error[`plannedDate_${index}`]}
                            value={point['plannedDate']}
                            type={DATE_TYPE}
                            settings={settings['plannedDate']}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Field
                            name={point.isLoading ? 'loadingVehicleStatus' : 'unloadingVehicleStatus'}
                            value={point.isLoading ? point['loadingVehicleStatus'] : point['unloadingVehicleStatus']}
                            source={point.isLoading ? 'loadingVehicleState' : 'unloadingVehicleState'}
                            error={point.isLoading ? error[`loadingVehicleStatus_${index}`] : error[`unloadingVehicleStatus_${index}`]}
                            type={STATE_TYPE}
                            settings={settings}
                            onChange={handleChange}
                            form={point}
                            columnsConfig={columnsConfig}
                        />
                    </Grid.Column>
                </Grid.Row>
                {point.isLoading ? (
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormField
                                name="shippingTime"
                                text="RoutePoint.ShippingTime"
                                value={point['shippingTime']}
                                settings={settings['shippingTime']}
                                error={error[`shippingTime_${index}`]}
                                type={TIME_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                ) : (
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormField
                                name="deliveryTimeFrom"
                                text="RoutePoint.DeliveryTimeFrom"
                                value={point['deliveryTimeFrom']}
                                settings={settings['deliveryTimeFrom']}
                                error={error[`deliveryTimeFrom_${index}`]}
                                type={TIME_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="deliveryTimeTo"
                                text="RoutePoint.DeliveryTimeTo"
                                value={point['deliveryTimeTo']}
                                settings={settings['deliveryTimeTo']}
                                error={error[`deliveryTimeTo_${index}`]}
                                type={TIME_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {point.isLoading ? (
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormField
                                name="routeLoadingArrivalTime"
                                value={point['routeLoadingArrivalTime']}
                                settings={settings['routeLoadingArrivalTime']}
                                error={error[`routeLoadingArrivalTime_${index}`]}
                                maxDate={point['routeLoadingDepartureTime']}
                                type={DATE_TIME_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="routeLoadingDepartureTime"
                                value={point['routeLoadingDepartureTime']}
                                settings={settings['routeLoadingDepartureTime']}
                                error={error[`routeLoadingDepartureTime_${index}`]}
                                minDate={point['routeLoadingArrivalTime']}
                                type={DATE_TIME_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                ) : (
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <FormField
                                name="routeUnloadingArrivalTime"
                                value={point['routeUnloadingArrivalTime']}
                                settings={settings['routeUnloadingArrivalTime']}
                                error={error[`routeUnloadingArrivalTime_${index}`]}
                                maxDate={point['routeUnloadingDepartureTime']}
                                type={DATE_TIME_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <FormField
                                name="routeUnloadingDepartureTime"
                                value={point['routeUnloadingDepartureTime']}
                                settings={settings['routeUnloadingDepartureTime']}
                                error={error[`routeUnloadingDepartureTime_${index}`]}
                                minDate={point['routeUnloadingArrivalTime']}
                                type={DATE_TIME_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                <Grid.Row columns={2}>
                    <Grid.Column>
                            <FormField
                                name={point.isLoading ? 'loadingDelayOnWay' : 'unloadingDelayOnWay'}
                                value={point.isLoading ? point['loadingDelayOnWay'] : point['unloadingDelayOnWay']}
                                error={point.isLoading ? error[`loadingDelayOnWay_${index}`] : error[`unloadingDelayOnWay_${index}`]}
                                type={TEXT_TYPE}
                                settings={point.isLoading ? settings['loadingDelayOnWay'] : settings['unloadingDelayOnWay']}
                                onChange={handleChange}
                            />
                    </Grid.Column>
                    {!point.isLoading &&
                        <Grid.Column>
                            <FormField
                                name="routePlannedUnloadingDepartureTime"
                                value={point['routePlannedUnloadingDepartureTime']}
                                settings={settings['routePlannedUnloadingDepartureTime']}
                                error={error[`routePlannedUnloadingDepartureTime_${index}`]}
                                maxDate={point['routePlannedUnloadingDepartureTime']}
                                type={DATE_TIME_TYPE}
                                onChange={handleChange}
                            />
                        </Grid.Column>
                    }
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                        <FormField
                            name="address"
                            value={point['address']}
                            settings={settings['address']}
                            error={error[`address_${index}`]}
                            type={BIG_TEXT_TYPE}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Route;
