import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
    changeSelectedColumnsRequest,
    columnsSelector,
    getConfigurationColunmsRequest, saveSelectedColumnsRequest,
    selectedColumnsSelector
} from "../../ducks/loadingData";
import DragAndDrop from "../DragAndDrop";
import {toast} from "react-toastify";

const TemplateSettings = ({ open, typeApi, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [fields, setFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);

    const selectedColumns = useSelector(selectedColumnsSelector) || [];
    const columns = useSelector(state => columnsSelector(state, selectedColumns)) || [];


    useEffect(() => {
        open && dispatch(getConfigurationColunmsRequest({typeApi}))
    }, [open]);

    const handleChange = selected => {
        dispatch(changeSelectedColumnsRequest(selected))
    };

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        if (columns && columns.find(i => i.isRequired)) {
            const column = columns.filter(i => i.isRequired);
            toast.error(t('Fields are required to create a carriage', {fields: column.map(i => t(i.displayNameKey)).join(', ')}));
        } else {
            dispatch(saveSelectedColumnsRequest({
                typeApi,
                callbackSuccess: handleClose
            }))
        }
    };

    return (
        <Modal open={open} id="fieldModal">
            <Modal.Header>{t('template_settings')}</Modal.Header>
            <Modal.Content scrolling>
                <div className="flex-container-justify">
                {(columns && columns.length > 0) ||
                (selectedColumns && selectedColumns.length) ? (
                    <DragAndDrop
                        key={'dnd' + typeApi}
                        type={typeApi}
                        left={columns}
                        right={selectedColumns}
                        isSettings
                        t={t}
                        search={""}
                        onChange={handleChange}
                    />
                ) : null}
                </div>
                <div>
                    {`* ${t('required field for creating transportation')}`}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t('CancelButton')}</Button>
                <Button primary onClick={handleSave}>{t('SaveButton')}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default TemplateSettings;
