import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import ReactDOM from 'react-dom';

export default ({ rows = [], backlightsConfig = {}, selector, selectedRows = new Set() }) => {
    const { markers = [], colors = [] } = backlightsConfig;
    rows.forEach(row => {
        const [rowEl] = document.getElementsByClassName(`${selector}_${row.id}`);

        const colored = (color) => {
            const childrensEl = [...rowEl.children];
            childrensEl.forEach(ch => {
                ch.style.background = color;
            });
        };

        if (selectedRows.has(row.id) && !rowEl.classList.contains('grid-row-selected')) {
            rowEl.className = `${rowEl.className} grid-row-selected`
        } else if (rowEl.classList.contains('grid-row-selected') && !selectedRows.has(row.id)) {
            rowEl.classList.remove(`grid-row-selected`);
        }

        // markers
        for (const marker of markers) {
            if (
                row.backlights.includes(marker) &&
                rowEl.children[0].getElementsByClassName(`grid-marker ${marker}`) &&
                !rowEl.children[0].getElementsByClassName(`grid-marker ${marker}`).length
            ) {
                const div = document.createElement('div');
                div.className = `grid-marker ${marker}`;
                rowEl.style.fontWeight = '600';
                rowEl.children[0].appendChild(div);

                break;
            } else if (
                !row.backlights.includes(marker) &&
                rowEl.children[0].getElementsByClassName(`grid-marker ${marker}`) &&
                rowEl.children[0].getElementsByClassName(`grid-marker ${marker}`).length
            ) {
                rowEl.style.fontWeight = '400';
                rowEl.children[0].lastChild && rowEl.children[0].lastChild.remove();
            }
        }

        //colors
        for (const colorMarker of colors.sort((a, b) => {
            if (a.priority > b.priority) {
                return 1;
            }
            if (a.priority < b.priority) {
                return -1;
            }

            return 0;
        })) {

            if (
                row.backlights.includes(colorMarker.name) &&
                !rowEl.classList.contains(`${colorMarker.name}`)
            ) {
                rowEl.className = `${rowEl.className} ${colorMarker.name}`;
                colored(colorMarker.color);
                break;
            } else if (row.backlights.includes(colorMarker.name) &&
                rowEl.classList.contains(`${colorMarker.name}`)) {
                colored(colorMarker.color);
                break;
            } else if (
                !row.backlights.includes(colorMarker.name) &&
                rowEl.classList.contains(`${colorMarker.name}`)
            ) {
                rowEl.classList.remove(`${colorMarker.name}`);
                colored('white');
            }
        }

        //messages
        if (
            row.backlightMessages &&
            rowEl.children[0].getElementsByClassName(`grid-messages`) &&
            !rowEl.children[0].getElementsByClassName(`grid-messages`).length
        ) {

            const div = document.createElement('div');
            div.className = `grid-messages`;
          
            ReactDOM.render(
                <Popup
                    basic
                    trigger={<Icon name="info circle" className="info-circle-backlight"/>}
                    content={row.backlightMessages}
                />,
                div,
            );
            rowEl.children[0].appendChild(div);
        } else if (
            row.backlightMessages &&
            rowEl.children[0].getElementsByClassName(`grid-messages`) &&
            rowEl.children[0].getElementsByClassName(`grid-messages`).length
        ) {
            rowEl.children[0].lastChild && rowEl.children[0].lastChild.remove();
            const div = document.createElement('div');
            div.className = `grid-messages`;
          
            ReactDOM.render(
                <Popup
                    basic
                    trigger={<Icon name="info circle" className="info-circle-backlight"/>}
                    content={row.backlightMessages}
                />,
                div,
            );
            rowEl.children[0].appendChild(div);
        } else if (
            !row.backlightMessages &&
            rowEl.children[0].getElementsByClassName(`grid-messages`) &&
            rowEl.children[0].getElementsByClassName(`grid-messages`).length
        ) {
            rowEl.children[0].lastChild && rowEl.children[0].lastChild.remove();
        }
    });
};
