import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Loader, Table } from 'semantic-ui-react';
import BodyCell from './body_cell';
import { connect } from 'react-redux';
import { checkForEditingRequest } from '../../../ducks/gridColumnEdit';
import { invokeMassUpdateRequest } from '../../../ducks/gridActions';
import { ORDERS_GRID, SHIPPINGS_GRID } from '../../../constants/grids';
import CustomCheckbox from '../../BaseComponents/CustomCheckbox';
import { LINK_TYPE } from '../../../constants/columnTypes';
import { backlightsConfigSelector } from '../../../ducks/profile';
import getBackLghts from '../../../utils/getBackLghts';

class Result extends Component {
    handleCheck = (e, row) => {
        const { selectedRows = new Set(), setSelected, onlyOneCheck, rows } = this.props;
        let newSelectedRows;

        if (e.nativeEvent.shiftKey && selectedRows.size > 0 && !selectedRows.has(row.id)) {
            newSelectedRows = new Set(selectedRows);
            const lastSelectedId = [...selectedRows][selectedRows.size - 1];

            const previousIndex = rows.findIndex(r => r.id === lastSelectedId) || 0;
            const currentIndex = rows.findIndex(r => r.id === row.id) || 0;

            if (currentIndex > previousIndex) {
                for (let index = previousIndex; index <= currentIndex; index++) {
                    newSelectedRows.add(rows[index].id);
                }
            } else {
                for (let index = previousIndex; index >= currentIndex; index--) {
                    newSelectedRows.add(rows[index].id);
                }
            }
        } else if (onlyOneCheck) {
            newSelectedRows = new Set();
            if (!selectedRows.has(row.id)) {
                newSelectedRows.add(row.id);
            }
        } else {
            newSelectedRows = new Set(selectedRows);
            newSelectedRows[!selectedRows.has(row.id) ? 'add' : 'delete'](row.id);
        }

        setSelected(newSelectedRows);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.rows !== prevProps.rows ||
            this.props.columns !== prevProps.columns ||
            this.props.selectedRows !== prevProps.selectedRows
        ) {
            const { rows = [], backlightsConfig, selectedRows } = this.props;

            getBackLghts({
                rows,
                backlightsConfig,
                selector: 'grid-row',
                t: this.props.t,
                selectedRows,
            });
        }
    }

    render() {
        const {
            columns = [],
            rows = [],
            goToCard,
            followLink,
            actions,
            isShowActions,
            selectedRows,
            loadList,
            disabledCheck,
            name,
            progress,
            t,
            checkForEditing,
            invokeMassUpdate,
        } = this.props;

        return (
            <Table.Body>
                {rows &&
                    rows.map((row, indexRow) => (
                        <Table.Row
                            key={row.id}
                            className={`grid-row grid-row_${row.id}`}
                            data-grid-id={row.id}
                        >
                            <Table.Cell
                                key={row.id + 'checkbox'}
                                className="small-column"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            >
                                <CustomCheckbox
                                    checked={!!selectedRows.has(row.id)}
                                    disabled={disabledCheck(row)}
                                    onChange={e => {
                                        this.handleCheck(e, row);
                                    }}
                                />
                            </Table.Cell>
                            {columns &&
                                columns.map((column, indexColumn) => {
                                    if (column && !column.isHide) {
                                        return (
                                            <BodyCell
                                            key={`cell_${row.id}_${column.name}`}
                                            value={
                                                row[column.name] && typeof row[column.name] === 'object' && !Array.isArray(row[column.name])
                                                    ? row[column.name].value
                                                    : row[column.name]
                                            }
                                            valueText={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                    ? row[column.name].name
                                                    : null
                                            }
                                            valueTooltip={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                    ? row[column.name].tooltip
                                                    : null
                                            }
                                            row={row}
                                            status={row.status}
                                            rowId={
                                                column.type === LINK_TYPE
                                                    ? column.source === SHIPPINGS_GRID &&
                                                    column.source !== name
                                                        ? row.shippingId
                                                        : row.id
                                                    : row.id
                                            }
                                            rowNumber={
                                                name === ORDERS_GRID
                                                    ? row.orderNumber
                                                    : row.shippingNumber
                                            }
                                            column={column}
                                            indexRow={indexRow}
                                            indexColumn={indexColumn}
                                            loadList={loadList}
                                            gridName={name}
                                            goToCard={goToCard}
                                            followLink={followLink}
                                            t={t}
                                            checkForEditing={checkForEditing}
                                            invokeMassUpdate={invokeMassUpdate}
                                        />
                                        );
                                    }
                                    
                                })}
                            <Table.Cell />
                            {isShowActions ? (
                                <Table.HeaderCell
                                    className="actions-column"
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {actions &&
                                        actions(row).map(action => (
                                            <Button
                                                key={row.id + action.name}
                                                actionname={action.name}
                                                actionbuttonname={action.buttonName}
                                                rowid={row.id}
                                                disabled={action.disabled}
                                                className="grid-action-btn"
                                                loading={
                                                    action.loadingId &&
                                                    action.loadingId.includes(row.id)
                                                }
                                                onClick={e =>
                                                    action.action(e, {
                                                        action,
                                                        row,
                                                        loadList,
                                                    })
                                                }
                                                size="mini"
                                            >
                                                {action.buttonName}
                                            </Button>
                                        ))}
                                </Table.HeaderCell>
                            ) : null}
                        </Table.Row>
                    ))}
                <div className="table-bottom-loader">
                    <Loader active={progress && rows.length} />
                </div>
            </Table.Body>
        );
    }
}

const mapStateToProps = state => {
    return {
        backlightsConfig: backlightsConfigSelector(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkForEditing: params => {
            dispatch(checkForEditingRequest(params));
        },
        invokeMassUpdate: params => {
            dispatch(invokeMassUpdateRequest(params));
        },
    };
};

export default withTranslation()(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Result),
);
