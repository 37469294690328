import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';
import FormField from '../formField';
import {useDispatch, useSelector} from "react-redux";
import {createDefaultValueSelector, getCardConfigRequest} from "../../../../ducks/gridCard";
import {ORDERS_GRID} from "../../../../constants/grids";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";

const Information = ({ form, onChange, uniquenessNumberCheck, settings, error, columnsConfig }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const defaultValues = useSelector(createDefaultValueSelector);

    useEffect(() => {
        !form.id && dispatch(getCardConfigRequest({name: ORDERS_GRID, notConfigLoad: true}));
    }, []);

    useEffect(() => {
        defaultValues &&
        Object.keys(defaultValues).forEach(key =>
            onChange(null, {name: key, value: defaultValues[key]}),
        );
    }, [defaultValues]);

    return (
        <Form className="tabs-card">
            {/* <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('general info')}</label>
                            <Segment>*/}
            <Grid>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <FormField
                            name="orderNumber"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onBlur={uniquenessNumberCheck}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="sapOrderNumber"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="clientOrderNumber"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="shipToCode"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('route')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            <FormField
                                                name="shippingDate"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="deliveryDate"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Form.Field>
                                                <label>{t('deliveryTime')}</label>
                                                <div className="temperature-fields">
                                                    <label>{t('time_from')}</label>
                                                    <FormField
                                                        noLabel
                                                        name="deliveryTimeFrom"
                                                        columnsConfig={columnsConfig}
                                                        settings={settings}
                                                        form={form}
                                                        error={error}
                                                        onChange={onChange}
                                                    />
                                                    <label>{t('time_to')}</label>
                                                    <FormField
                                                        noLabel
                                                        name="deliveryTimeTo"
                                                        columnsConfig={columnsConfig}
                                                        settings={settings}
                                                        form={form}
                                                        error={error}
                                                        onChange={onChange}
                                                    />
                                                </div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="deliveryTimeComments"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            <FormField
                                                name="isOverload"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="deliveryCity"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="deliveryRegion"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="deliveryNumber"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            <FormField
                                                name="shippingAddress"
                                                rows={2}
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="deliveryAddress"
                                                rows={2}
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('volume-weight characteristics')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row columns={3}>
                                        <Grid.Column>
                                            <FormField
                                                name="monoPalletsCount"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="mixPalletsCount"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="palletsCount"
                                                columnsConfig={columnsConfig}
                                                isReadOnly
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            <FormField
                                                name="boxesCount"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="weightKg"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="volume"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('Additional Information')}</label>
                            <Segment>
                                <Grid>
                                    <Grid.Row columns="equal">
                                        <Grid.Column>
                                            <FormField
                                                name="orderComments"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="deliveryTypeComments"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <FormField
                                                name="deliveryInstructions"
                                                columnsConfig={columnsConfig}
                                                settings={settings}
                                                form={form}
                                                error={error}
                                                onChange={onChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <FormField
                            name="deliveryType"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="tarifficationType"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="vehicleTypeId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <FormField
                            name="clientName"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="carrierId"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <FormField
                            name="claimTypes"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="boxesMissing"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            name="boxDefectsShipping"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {/*  </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>*/}
        </Form>
    );
};

export default Information;
