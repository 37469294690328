import React from 'react';
import { Form, Radio } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Bool = ({
    value,
    name,
    onChange,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    emptyValue,
    isGrid,
    formRef,
}) => {
    const { t } = useTranslation();

    const handleChange = (e, data) => {
        onChange(e, data);

        if (isGrid) {
            setTimeout(() => {
                formRef &&
                formRef.current &&
                formRef.current.handleSubmit &&
                formRef.current.handleSubmit();
            }, 100);
        }
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{ __html: t(text || name) }} />
                </label>
            ) : null}
            <div className="bool-radio-button">
                <Radio
                    label={t('Yes')}
                    name={name}
                    value={true}
                    checked={value === true}
                    className={className}
                    disabled={isDisabled}
                    onChange={handleChange}
                />
                <Radio
                    label={t('No')}
                    name={name}
                    value={false}
                    checked={value === false}
                    className={className}
                    disabled={isDisabled}
                    onChange={handleChange}
                />
                {emptyValue === 'allowed' && (
                    <Radio
                        label={t('not_chosen')}
                        name={name}
                        value={null}
                        checked={value !== false && value !== true}
                        className={className}
                        disabled={isDisabled}
                        onChange={handleChange}
                    />
                )}
            </div>
        </Form.Field>
    );
};
export default Bool;
