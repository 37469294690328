import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Popup, Table, Icon } from 'semantic-ui-react';
import FormField from '../../components/BaseComponents';
import {
    getBillDetailsByIdRequest,
    getBillDetailsGridConfigRequest,
    getBillDetailsListRequest,
    billDetailsExportToExcelRequest,
    billDetailsInvokeBulkUpdateRequest,
    getBillDetailsSubGridConfigRequest,
    billDetailsSubGridConfigSelector,
    bulkUpdateProgressSelector,
    exportProgressSelector,
    billDetailsListSelector,
    titleInfoSelector,
    billDetailsConfigSelector,
} from '../../ducks/gridList';
import {
    columnsSelector,
    getReportBillDetailsRequest,
    progressSelector,
    reportSelector,
} from '../../ducks/reports';
import { BILL_DETAILS_REPORT_TYPE } from '../../constants/reportType';
import { useTranslation } from 'react-i18next';
import Block from '../../components/CardLayout/components/block';
import CellValue from '../../components/ColumnsValue';
import FacetField from '../../components/FilterComponents';
import { ORDERS_GRID } from '../../constants/grids';

const BillDetailsGrid = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id, name } = props;
    const ref = useRef(null);

    let [filter, setFilter] = useState({});
    let [sort, setSort] = useState({});
    let [billDetailInfo, setBillDetailInfo] = useState(null);
    let [open, setOpen] = useState(false);
    let [openSub, setOpenSub] = useState({});
    let [activeCell, setActiveCell] = useState(null);
    let [valueForm, setValue] = useState(null);
    let [valueChange, setValueChanged] = useState(false);

    const columns = useSelector(columnsSelector);
    const report = useSelector(reportSelector);
    const title = useSelector(titleInfoSelector);
    const config = useSelector(billDetailsConfigSelector);
    const subConfig = useSelector(billDetailsSubGridConfigSelector);
    const rows = useSelector(billDetailsListSelector);
    const exportProgress = useSelector(exportProgressSelector);
    const loading = useSelector(progressSelector);

    useEffect(() => {
        let rowSubTables = {};
        rows && rows.length > 0 && rows.map(row => {
            if (row.orders.length > 0) {
                rowSubTables = {...rowSubTables, [row.shippingId]: false};
            }
        })
        setOpenSub(rowSubTables);
    }, [rows]);

    useEffect(() => {
        dispatch(getBillDetailsByIdRequest({id: id}));
        dispatch(getBillDetailsGridConfigRequest());
        dispatch(getBillDetailsSubGridConfigRequest());
    }, []);

    useEffect(() => {
        dispatch(getBillDetailsListRequest({id: title.id}));
        setBillDetailInfo(title);
    }, [title]);


    useEffect(() => {
        open && document.addEventListener('mousedown', handleClickOutside, true);
        !open && document.removeEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [open, valueForm]);


    const handleClickOutside = event => {
        if (!event.path.find(item => item.className === "on-edit-grid-cell")) {
            if (valueChange) {
                handleSave();
            } else {
                handleClose();
            }
            
        }
    };

    const handleClick = (column, row) => {
        if (!column.isReadOnly && !open) {
            setOpen({column: column.name, row: row.shippingId});
            setValue(
                row[column.name] && typeof row[column.name] === 'object'
                    ? row[column.name].value
                    : row[column.name]
            )
            setActiveCell({column, row});
        }  
    };

    const handleClose = () => {
        setOpen(false);
        setActiveCell(null);
        setValue(null);
    };

    const handleSave = () => {
        dispatch(billDetailsInvokeBulkUpdateRequest({
            ids: [activeCell.row.shippingId],
            name: billDetailInfo.id,
            field: [activeCell.column.name],
            value:
                typeof valueForm === 'number' || typeof valueForm === 'boolean'
                    ? valueForm.toString()
                    : valueForm,
            callbackSuccess: () => {
                handleClose();
                dispatch(getBillDetailsByIdRequest({id: billDetailInfo.id}));
                dispatch(getBillDetailsListRequest({id: billDetailInfo.id}));
            },
        }));
    };

    const handleChange = (e, { value }) => {
        setValueChanged(true);
        setValue(value);
    };



    const handleChangeFilter = (e, { name, value }) => {
        setFilter(filter => {
            let newFilter = { ...filter };

            if (value) {
                newFilter = {
                    ...filter,
                    [name]: value,
                };
            } else {
                delete newFilter[name];
            }

            return newFilter;
        });
    };

    const handleExportToExcel = () => {
        dispatch(billDetailsExportToExcelRequest({id: billDetailInfo.id, t}));
    }



    const item = {
        menuItem: t("billDetailNumber") + " " + name,
        render: () => (
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <div style={{width: "30px"}}></div>
                        </Table.HeaderCell>
                        {config && config.columns && config.columns.map((column, i) => {
                            return (
                                <Table.HeaderCell>
                                    <FacetField
                                        key={'facet' + column.name}
                                        notWrapHeader
                                        index={i}
                                        gridName={ORDERS_GRID}
                                        name={column.name}
                                        displayNameKey={column.displayNameKey}
                                        sort={sort && sort.name === column.name ? sort.desc : null}
                                        setSort={setSort}
                                        type={column.type}
                                        value={filter[column.name]}
                                        filters={filter}
                                        setFilter={handleChangeFilter}
                                        source={column.source}
                                    />
                                </Table.HeaderCell>
                            );
                        })}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows.map((row, indexRow) => (
                        <>
                        <Table.Row key={row.id} onClick={
                            () => {
                                       
                                
                            }
                        }>
                            <Table.Cell>
                                <div className="bill-detail-sub-grid__icon" onClick={
                                    () => {
                                        const newOpenSub = JSON.parse(JSON.stringify(openSub));
                                        if (newOpenSub[row.shippingId] !== undefined) {
                                            newOpenSub[row.shippingId] = newOpenSub[row.shippingId] ? false : true;
                                        } 
                                        setOpenSub(newOpenSub);
                                    }
                                }>
                                    {row.orders.length > 0 &&
                                        <Icon 
                                            name={openSub[row.shippingId] && row.orders.length > 0 ? "angle up" : "angle down"}
                                            size="small"
                                        />
                                    }
                                </div>
                            </Table.Cell>
                            {config && config.columns && config.columns.map((column, indexColumn) => (
                                <Table.Cell 
                                    style={open.column === column.name && open.row === row.shippingId ? {overflowX: "auto", paddingLeft: "0"} : {}}
                                    key={`${row.id}_${column.name}`} 
                                    onClick={() => handleClick(column, row)}
                                    ref={ref}
                                    className={open.column === column.name && open.row === row.shippingId ? "on-edit-grid-cell" : ""}>
                                    {open.column === column.name && open.row === row.shippingId ? (
                                        <div className="cell-grid-form">
                                            <Form>
                                                <FormField
                                                    {...column}
                                                    isGrid
                                                    noLabel
                                                    value={valueForm}
                                                    onChange={handleChange}
                                                />
                                            </Form>
                                        </div>
                                    ) : (
                                        <CellValue
                                            {...column}
                                            indexRow={indexRow}
                                            indexColumn={indexColumn}
                                            value={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                    ? row[column.name].value
                                                    : row[column.name]
                                            }
                                            valueText={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                    ? row[column.name].name
                                                    : null
                                            }
                                            valueTooltip={
                                                row[column.name] && typeof row[column.name] === 'object'
                                                    ? row[column.name].tooltip
                                                    : null
                                            }
                                            rowId={row.id}
                                            t={t}
                                        />
                                    )}
                                </Table.Cell>
                            ))}
                        </Table.Row>
                        {openSub[row.shippingId] && row.orders.length > 0 &&
                            <Table.Row style={{height: `${row.orders.length * 40 + 80}px`, 
                            padding: "10px"}}>
                                <Table style={{maxHeight: "100px", position: "absolute", border: "1px solid rgba(0, 0, 0, 0.1)", zIndex: "1", left: "5%", marginTop: "10px", boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)"}}>
                                    <Table.Header>
                                        <Table.Row>
                                            {subConfig && subConfig.columns && subConfig.columns.map((column, i) => {
                                                return (
                                                    <Table.HeaderCell>
                                                        <FacetField
                                                            key={'facet' + column.name}
                                                            notWrapHeader
                                                            index={i}
                                                            gridName={ORDERS_GRID}
                                                            name={column.name}
                                                            displayNameKey={column.displayNameKey}
                                                            sort={sort && sort.name === column.name ? sort.desc : null}
                                                            setSort={setSort}
                                                            type={column.type}
                                                            value={filter[column.name]}
                                                            filters={filter}
                                                            setFilter={handleChangeFilter}
                                                            source={column.source}
                                                        />
                                                    </Table.HeaderCell>
                                                );
                                            })}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {row.orders.map((row, indexRow) => (
                                            <Table.Row key={row.id}>
                                                {subConfig && subConfig.columns && subConfig.columns.map((column, indexColumn) => (
                                                    <Table.Cell 
                                                        style={open.column === column.name && open.row === row.shippingId ? {overflowX: "auto", paddingLeft: "0"} : {}}
                                                        key={`${row.id}_${column.name}`} 
                                                        onClick={() => handleClick(column, row)}
                                                        ref={ref}
                                                        className={open.column === column.name && open.row === row.shippingId ? "on-edit-grid-cell" : ""}>
                                                            <CellValue
                                                                {...column}
                                                                indexRow={indexRow}
                                                                indexColumn={indexColumn}
                                                                value={
                                                                    row[column.name] && typeof row[column.name] === 'object'
                                                                        ? row[column.name].value
                                                                        : row[column.name]
                                                                }
                                                                valueText={
                                                                    row[column.name] && typeof row[column.name] === 'object'
                                                                        ? row[column.name].name
                                                                        : null
                                                                }
                                                                valueTooltip={
                                                                    row[column.name] && typeof row[column.name] === 'object'
                                                                        ? row[column.name].tooltip
                                                                        : null
                                                                }
                                                                rowId={row.id}
                                                                t={t}
                                                            />
                                                    </Table.Cell>
                                                ))}
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>    
                            </Table.Row>}
                        </>
                    ))}
                </Table.Body>
            </Table>
        ),
        actions: () => [
            <div>
                <p className="bill-detail-number">
                    <p className="bill-detail-status">
                        {t("billDetailConfirmationStatus") + " "}
                    </p>
                    {billDetailInfo && billDetailInfo.status}
                </p>
            </div>,
            <Popup
                content={t('exportToExcel')}
                position="bottom right"
                trigger={
                    <Button
                        icon="download"
                        loading={exportProgress}
                        onClick={handleExportToExcel}
                    />
                }
            />,
        ],
    };

    return (
        <div className="container">
            <div className="report">
                <div className="report_table">
                    <Block item={item} actions={item.actions} loading={loading} isFullScreen />
                </div>
            </div>
        </div>
    );
};

export default BillDetailsGrid;

