import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Popup, Table } from 'semantic-ui-react';
import Date from '../../components/BaseComponents/Date';
import useReactRouter from 'use-react-router';
import { dateToString } from '../../utils/dateTimeFormater';
import {
    columnsSelector,
    exportProgressSelector,
    getReportBillDetailsRequest,
    formBillDetailsRequest,
    progressSelector,
    reportSelector,
    billDetailsSelector,
    formProgressSelector,
} from '../../ducks/reports';
import { BILL_DETAILS_REPORT_TYPE } from '../../constants/reportType';
import { BILL_DETAILS_GRID_LINK } from '../../router/links';
import { useTranslation } from 'react-i18next';

const BillDetailsReport = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { location, history } = useReactRouter();

    let [params, setParams] = useState({
        startDate: dateToString(),
        endDate: dateToString()
    });

    let [isDisabledBtn, setIsDisadledBtn] = useState(true);

    let [filter, setFilter] = useState({});
    let [sort, setSort] = useState({});

    const report = useSelector(billDetailsSelector);
    const exportProgress = useSelector(exportProgressSelector);
    const loading = useSelector(progressSelector);
    const formProgress = useSelector(formProgressSelector);

    useEffect(() => {
        getReport();
    }, [filter, sort]);


    useEffect(() => {
        if (formProgress === false) {
            dispatch(getReportBillDetailsRequest(mapData()));
        }
    }, [formProgress])

    const mapData = () => {
        return {
            type: BILL_DETAILS_REPORT_TYPE
        };
    };

    const getReport = () => {
        dispatch(getReportBillDetailsRequest(mapData()));
    };

    const handleChangeParams = (e, { name, value }) => {
        const newParams = {
            ...params,
            [name]: value,
        };
        setParams(newParams);
        setIsDisadledBtn(false);
    };

    const generateReport = () => {
        dispatch(formBillDetailsRequest({params}));
    };

    const handleFollowLink = (id, name) => {
        let link = BILL_DETAILS_GRID_LINK;
        history.push({
            pathname: name ? link.replace(':name', "grid") : link,
            state: {
                pathname: history.location.pathname,
                id: id,
                name: name,
            },
        })
    };

    return (
        <div className="container">
            <div className="report">
                <div className="report_params">
                    <Form>
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <Date
                                        name="startDate"
                                        text="billDetailsStartDate"
                                        value={params.startDate}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Date
                                        name="endDate"
                                        text="billDetailsEndDate"
                                        value={params.endDate}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column textAlign="right"
                                style={{display: "flex", alignItems: "flex-end", flexDirection: "row-reverse"}}>
                                    <Button primary disabled={isDisabledBtn} onClick={generateReport}>
                                        {t('Generate report')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={4}>
                                
                            </Grid.Row>
                        </Grid>
                        <Grid className="bill-details-list">
                            {report && Object.keys(report).map((item, index) =>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <p className="link-cell" onClick={() => handleFollowLink(report[item].id, report[item].number)}>
                                            {t("billDetailNumber") + " " + report[item].number}
                                        </p> 
                                    </Grid.Column>
                                    <Grid.Column>
                                        <p className="bill-detail-number">
                                            <p className="bill-detail-status">
                                                {t("billDetailConfirmationStatus") + " "}
                                            </p>
                                            {report[item].status}
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>
                    </Form>
                </div>
                {/* <div className="report_table">
                    <Block item={item} actions={item.actions} loading={loading} isFullScreen />
                </div> */}
            </div>
        </div>
    );
};

export default BillDetailsReport;
