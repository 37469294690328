import React from 'react';
import CardLayout from '../../../components/CardLayout';
import FormField from '../../../components/BaseComponents';

const OtherCard = ({
    form,
    onChangeForm,
    name,
    id,
    onClose: beforeClose,
    settings,
    title,
    actionsFooter,
    onClose,
    actionsHeader,
    loading,
    load,
    error,
    columnsConfig: columns,
}) => {

    return (
        <div>
            <CardLayout
                title={title}
                actionsFooter={actionsFooter}
                onClose={onClose}
                loading={loading}
            >
                <div className="ui form dictionary-edit">
                    {Object.keys(columns).map(key => (
                        <FormField
                            {...columns[key]}
                            noScrollColumn={columns[key]}
                            key={key}
                            settings={settings[key]}
                            error={error[key]}
                            value={form[key]}
                            onChange={onChangeForm}
                        />
                    ))}
                </div>
            </CardLayout>
        </div>
    );
};

export default OtherCard;
