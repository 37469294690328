import React, {useCallback, useEffect} from 'react';
import {Form, Grid, Segment} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import { getReportBillDetailsRequest, billDetailsSelector } from '../../../../ducks/reports';
import { billDetailsExportToExcelRequest } from '../../../../ducks/gridList';
import FormField from '../formField';
import useReactRouter from 'use-react-router';
import { BILL_DETAILS_GRID_LINK } from '../../../../router/links';
import { BILL_DETAILS_REPORT_TYPE } from '../../../../constants/reportType';

const fields = [
    'basicDeliveryCostWithoutVAT',
    'downtimeRate',
    'otherCosts',
];

const Costs = ({ form = {}, onChange, settings, error, columnsConfig }) => {
    const { t } = useTranslation();
    const { location, history } = useReactRouter();
    const dispatch = useDispatch();

    const report = useSelector(billDetailsSelector);

    useEffect(() => {
        form.billState === "billDetailingConfirmed" && dispatch(getReportBillDetailsRequest({type: BILL_DETAILS_REPORT_TYPE}));
    }, [form]);

    const handleExportToExcel = () => {
        let billDetailId = null;
        Object.keys(report).map(key => {
            if (report[key].number === form.billDetailNumber) {
                billDetailId = report[key].id;
            }
        });
        dispatch(billDetailsExportToExcelRequest({id: billDetailId}));
    };

    const handleChange = useCallback((e, { name, value }) => {
        onChange(e, { name, value });

        let totalDeliveryCostWithoutVAT = 0;

        if (name !== 'orderCosts') {
            totalDeliveryCostWithoutVAT = value ? parseFloat(value) : 0;
        } else if (value && value.length) {
            value.forEach(item => {
                totalDeliveryCostWithoutVAT = totalDeliveryCostWithoutVAT + (item.returnCostWithoutVAT ? parseFloat(item.returnCostWithoutVAT) : 0);
            })
        }

        fields.filter(field => field !== name).forEach(field => {
            if (field !== 'orderCosts') {
                totalDeliveryCostWithoutVAT =
                    totalDeliveryCostWithoutVAT + (form[field] ? parseFloat(form[field]) : 0);
            } else if (form[field] && form[field].length) {
                form[field].forEach(item => {
                    totalDeliveryCostWithoutVAT = totalDeliveryCostWithoutVAT + (item.returnCostWithoutVAT ? parseFloat(item.returnCostWithoutVAT) : 0);
                })
            }

        });

        onChange(e, {
            name: 'totalDeliveryCostWithoutVAT',
            value: totalDeliveryCostWithoutVAT,
        });

        onChange(e, {
            name: 'totalDeliveryCost',
            value: (parseFloat(totalDeliveryCostWithoutVAT) * 120) / 100,
        });

    }, [form]);

    const handleFollowLink = (id, name) => {
        let link = BILL_DETAILS_GRID_LINK;
        history.push({
            pathname: name ? link.replace(':name', "grid") : link,
            state: {
                pathname: history.location.pathname,
                id: id,
                name: name,
            },
        })
    };

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('deliveryCostTitle')}</label>
                            <Segment>
                                <FormField
                                    name="basicDeliveryCostWithoutVAT"
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    onChange={onChange}
                                />
                                <FormField
                                    name="totalDeliveryCostWithoutVAT"
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    onChange={onChange}
                                />
                                <FormField
                                    name="totalDeliveryCost"
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    onChange={onChange}
                                />
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t('additionalCostsTitle')}</label>
                            <Segment>
                                <FormField
                                    name="downtimeRate"
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    onChange={handleChange}
                                />
                                <FormField
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    name="returnCostWithoutVAT"
                                    onChange={onChange}
                                />
                                <FormField
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    name="otherCosts"
                                    onChange={handleChange}
                                />
                                <FormField
                                    columnsConfig={columnsConfig}
                                    settings={settings}
                                    form={form}
                                    error={error}
                                    name="extraPointCostsWithoutVAT"
                                    onChange={onChange}
                                />
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="costsComments"
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="forwarderTransferCost"
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="carrierRequestEndTime"
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="actualDocumentsReturnDate"
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="deliveryInvoiceNumber"
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="blankArrivalRate"
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="unloadingDowntimeCost"
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="loadingDowntimeCost"
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="trucksDowntime"
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="additionalCostsComments"
                            text="additionalCostsCommentsTitle"
                            placeholderKey="additionalCostsCommentsPlaceholder"
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="costsConfirmedByShipper"
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormField
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            name="blankArrival"
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <FormField
                            name="billState"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field>
                            {columnsConfig && columnsConfig.billDetailNumber &&
                                !columnsConfig.billDetailNumber.noLabel && form.billDetailNumber ? (
                                    <label className={columnsConfig.billDetailNumber.isDisabled ? 'label-disabled' : null}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: `${t(columnsConfig.billDetailNumber.name)}${columnsConfig.billDetailNumber.isRequired ? ' *' : ''}`,
                                            }}
                                        />
                                    </label>
                                ) : null 
                            }
                            {form.billDetailNumber &&
                                <p className="link-cell" onClick={() => handleFollowLink(form.billDetailId, form.billDetailNumber)}>
                                    {t("billDetailNumber") + " " + form.billDetailNumber}
                                </p> 
                            }
                            {/* {form.billState === "billDetailingConfirmed" && form.billDetailNumber &&
                                <p className="link-cell" onClick={() => handleExportToExcel()}>
                                    {t("billDetailNumber") + " " + form.billDetailNumber}
                                </p> 
                            } */}
                            
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default Costs;
